import './App.css';
import {React, useState, useEffect} from 'react';
import {
  EthereumClient,
  walletConnectProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { Web3Button } from "@web3modal/react";
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { Transaction } from './Transaction';
import Web3 from 'web3';

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [action, setAction] = useState(urlParams.get('actionString'));
  const chains = [goerli];

  // Wagmi client
  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: "1d359a7501ab700043d076596793066b" })
  ]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: [
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      })
    ],
    provider,
  });
  ;

  let sampleText = `ACTION:\n${action.toString()}\n\nSHA-HASH:\n${Web3.utils.soliditySha3(action)}`;
  const ethereumClient = new EthereumClient(wagmiClient, chains);

 return (
    <div className='App'>
      <WagmiConfig client={wagmiClient}>
        <br></br><br></br>
        <Web3Button /><br></br><br></br>
        <textarea style={{height: 150, width: 155, overflowY: "scroll"}} id="transactionInfo">
          {sampleText}
        </textarea>
        <br></br>
        <Transaction action={action}/>
      </WagmiConfig>
      <Web3Modal
        projectId="1d359a7501ab700043d076596793066b"
        ethereumClient={ethereumClient}
      />
    </div>
  );
}

export default App;
