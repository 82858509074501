import * as React from 'react'
import {
    usePrepareContractWrite,
    useContractWrite,
    useWaitForTransaction,
} from 'wagmi'
import { goerli } from 'wagmi';
import Web3 from 'web3';


export function Transaction(props) {
    let web3 = new Web3();
    const { config } = usePrepareContractWrite({
        address: '0xE9DE2e6810332c2AeC160c855e648F9F2d93EA0b',
        abi: [
            {
              "inputs": [],
              "name": "getStateHash",
              "outputs": [
                {
                  "internalType": "bytes32",
                  "name": "",
                  "type": "bytes32"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [
                {
                  "internalType": "bytes32",
                  "name": "action",
                  "type": "bytes32"
                }
              ],
              "name": "setStateHash",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            }
          ],
        functionName: 'setStateHash',
        args: [web3.utils.soliditySha3(props.action)],
        chainId: goerli.id,
        onSuccess(data) {
            console.log('Success', data)
        },      
    })

    const { data, write } = useContractWrite(config);
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    return (
    <div>
      <button disabled={!write || isLoading} onClick={() => write()}
      style={{borderWidth: 0, padding: 10, marginTop: 10, fontWeight: 600, fontSize: 16, color: "white", backgroundColor: "var(--w3m-color-fg-accent)", borderRadius: 12}}
      >
        {isLoading ? 'Minting...' : 'Mint Transaction'}
      </button>
      <br></br>
      {isSuccess && (
        <div>
          Successfully minted your NFT!
          {window.ReactNativeWebView.postMessage(`https://goerli.etherscan.io/tx/${data?.hash}`)}
          <div>
            <a href={`https://goerli.etherscan.io/tx/${data?.hash}`}>Etherscan</a>
          </div>
        </div>
      )}
    </div>
    )
}
